import { useForm } from "react-hook-form";
import { useState, useMemo } from "react";
import { Notify } from "@flexisaf/flexibull2";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDebounce, useToggle } from "react-use";
import * as yup from "yup";

import { SrmsStaff, useSearchSrmsStaffsQuery } from "../users-api";
import { Duration } from "@/utils/enums";
import { Role, useGetRolesQuery } from "@/features/authorization";

export const MAX_USER_SIZE = 5;
const missingFieldsMsg = {
    department: "User does not have a department",
    email: "User does not have an email address",
    phone: "User does not have a phone number",
};
const validation = yup.object({
    users: yup.array().label("User").min(1, "No user was added"),
    unit: yup.string().label("Unit").required(),
    userRoleRequest: yup.array().label("User").min(1, "No role was added"),
});

export function useUserForm() {
    const form = useForm({
        resolver: yupResolver(validation),
        defaultValues: {
            users: [] as SrmsStaff[],
            unit: "",
            userRoleRequest: [] as Role[],
        },
    });

    const formValues = form.watch();
    const validationError = form.formState.errors;
    const { userRoleRequest, unit } = formValues;

    const [searchStaffValue, setSearchStaffValue] = useState("");
    const [isShowingFoundUsers, toggleShowFoundUsers] = useToggle(false);
    const [debouncedSearchStaffValue, setDebouncedSearchStaffValue] = useState("");
    const [usersIdMap, setUsersIdMap] = useState<Record<string, boolean>>({});
    const [rolesIdMap, setRolesIdMap] = useState<Record<string, boolean>>({});

    useDebounce(() => setDebouncedSearchStaffValue(searchStaffValue), Duration.Mid, [
        searchStaffValue,
    ]);

    const { data: staff, ...searchStaffMeta } = useSearchSrmsStaffsQuery(
        {
            "search-key": debouncedSearchStaffValue,
        },
        { skip: !debouncedSearchStaffValue }
    );

    const { data: rolesData, ...getRolesMeta } = useGetRolesQuery();

    const isMaxUsersReached = useMemo(
        () => formValues.users?.length >= MAX_USER_SIZE,
        [formValues.users]
    );

    const addUser = (user: SrmsStaff) => {
        const requiredFields = ["department", "email", "phone"];
        const missingFields = requiredFields.filter((field) => !user[field]);

        if (missingFields.length > 0) {
            missingFields.forEach((field) =>
                Notify(missingFieldsMsg[field], {
                    status: "error",
                })
            );
            return;
        }

        const { users } = formValues;

        if (Array.isArray(users)) {
            const isUserExist = users.some((existingUser) => existingUser.staffId === user.staffId);
            if (isMaxUsersReached || isUserExist) {
                return;
            }
        }

        const newUsers = Array.isArray(users) ? [...users, user] : [user];

        try {
            validation.validateSyncAt("users", { users: newUsers });
            setUsersIdMap((prev) => ({ ...prev, [user.staffId]: true }));
            form.setValue("users", newUsers);
            form.clearErrors("users");
            toggleShowFoundUsers();
        } catch (err) {
            form.setError("users", { message: (err as yup.ValidationError).message });
        }
    };

    const removeUser = (userId: string) => {
        const { users } = formValues;
        if (users?.length) {
            setUsersIdMap(({ [userId]: toRemove, ...rest }) => ({ ...rest }));
            form.setValue(
                "users",
                users.filter((user) => user.staffId !== userId)
            );
        }
    };

    const addRole = (role: Role) => {
        if (!role.privileges) return;
        try {
            const newRoles = userRoleRequest ? [...userRoleRequest, role] : [role];
            validation.validateSyncAt("userRoleRequest", { userRoleRequest: newRoles });
            setRolesIdMap((prev) => ({ ...prev, [role.id]: true }));
            form.setValue("userRoleRequest", newRoles);
            form.clearErrors("userRoleRequest");
        } catch (err) {
            form.setError("users", { message: (err as yup.ValidationError).message });
        }
    };

    const removeRole = (roleId: string) => {
        if (userRoleRequest) {
            setRolesIdMap(({ [roleId]: toRemove, ...rest }) => ({ ...rest }));

            form.setValue(
                "userRoleRequest",
                userRoleRequest.filter(({ id }) => id !== roleId)
            );
        }
    };

    const foundStaff = useMemo(
        () => staff?.filter(({ staffId }) => !usersIdMap[staffId]) ?? [],
        [staff, usersIdMap]
    );

    const roles = useMemo(
        () =>
            rolesData?.entities.filter(({ id, description }) => {
                let isVisible = !rolesIdMap[id];
                if (unit) {
                    isVisible = description === unit;
                }
                return isVisible;
            }) ?? [],
        [rolesData, rolesIdMap, unit]
    );

    const loading = {
        isSearchingUsers: searchStaffMeta.isLoading || searchStaffMeta.isFetching,
        isLoadingRoles: getRolesMeta.isLoading,
    };

    const errors = {
        searchUsersError: searchStaffMeta.error,
        rolesError: getRolesMeta.error,
        usersValidation: validationError.users,
        roleRequestValidation: validationError.userRoleRequest,
    };

    const actions = {
        onSearch: setSearchStaffValue,
        toggleShowFoundUsers,
        addUser,
        removeUser,
        addRole,
        removeRole,
    };

    return {
        isMaxUsersReached,
        isShowingFoundUsers,
        searchStaffValue,
        form,
        formValues,
        roles,
        foundStaff,
        loading,
        errors,
        actions,
    };
}
