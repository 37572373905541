import { Icon } from "@/features/ui/base/icon";
import { Box, Spacer, Text, Button } from "@flexisaf/flexibull2";
import { Role } from "../authorization";
import { theme } from "@/style";
import { snakeToTitleCase } from "@/utils/string-utils";

type SelectedRoleProps = { role: Role; onDelete?: (id: string) => void };
export const SelectedRole = ({ role, onDelete }: SelectedRoleProps) => (
    <Box
        display="flex"
        style={{ padding: "4px 0", alignItems: "center", justifyContent: "space-between" }}
    >
        <Text> {snakeToTitleCase(role.name)}</Text>
        {onDelete && (
            <Box display="flex">
                <Spacer direction="horizontal" space={4} />
                <Button plain onClick={() => onDelete(role.id)} color={theme.PrimaryRed}>
                    <Icon icon="minus-circle" color={theme.PrimaryRed} />
                </Button>
            </Box>
        )}
    </Box>
);
