import { Notify } from "@flexisaf/flexibull2";

import { baseApi } from "@/store/base-api";
import { encodeInterpolatedUrl } from "@/utils/string-utils";
import { ContactType, Methods } from "@/utils/enums";
import { List, QueryFulfilledError } from "@/types/common";

const PASSWORD_UPDATED_MESSAGE = "Password updated successfully";
const STAFF_ACTIVATED_MESSAGE = "User activated successfully";
const STAFF_DEACTIVATED_MESSAGE = "User deactivated successfully";

export const usersApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getStaff: builder.query<List<Staff>, StaffParams>({
            query: (params) => ({
                url: "/staff/search",
                params,
            }),
            providesTags: ["STAFF"],
        }),
        activateStaff: builder.mutation<unknown, string>({
            query: (id) => ({
                url: encodeInterpolatedUrl`/staff/activate/${id}`,
                method: Methods.Put,
            }),
            invalidatesTags: ["STAFF"],
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify(STAFF_ACTIVATED_MESSAGE)).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        deactivateStaff: builder.mutation<unknown, string>({
            query: (id) => ({
                url: encodeInterpolatedUrl`/staff/deactivate/${id}`,
                method: Methods.Put,
            }),
            invalidatesTags: ["STAFF"],
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify(STAFF_DEACTIVATED_MESSAGE)).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        searchSrmsStaffs: builder.query<SrmsStaff[], SearchSrmsStaffParams>({
            query: (params) => ({
                url: "srms/staff/search",
                params,
            }),
            transformResponse: transformStaffResponse,
        }),
        getUser: builder.query<UserProfile, string>({
            query: (id: string) => ({
                url: encodeInterpolatedUrl`/users/${id}`,
            }),
        }),
        createUser: builder.mutation<unknown, NewUserRequest>({
            query: (data) => ({
                url: "/users/bulk-users-creation",
                method: Methods.Post,
                data: transformNewUserReq(data),
            }),
            invalidatesTags: ["USERS"],
            async onQueryStarted(req, { queryFulfilled: qf }) {
                qf.then(() => Notify(getUserCreatedMsg(req))).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        updatePassword: builder.mutation<unknown, PasswordUpdateRequest>({
            query: (data) => ({
                url: "/auth/me/password-change",
                method: Methods.Patch,
                data,
            }),
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify(PASSWORD_UPDATED_MESSAGE)).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        updateUser: builder.mutation<unknown, UpdateUserRequest>({
            query: ({ id, ...data }) => ({
                url: encodeInterpolatedUrl`/users/${id}`,
                method: Methods.Patch,
                data,
            }),
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify(getUserUpdatedMsg())).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: "error" })
                );
            },
        }),
    }),
});

function transformStaffResponse(staff: SrmsStaffResponse[]): SrmsStaff[] {
    return staff.map(({ firstName, lastName, ...restDetails }) => {
        const fullName = `${firstName} ${lastName}`;
        return {
            fullName,
            firstName,
            lastName,
            ...restDetails,
        };
    });
}

function transformNewUserReq(data): NewUserRequest[] {
    return data.users.map((user) => ({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phone,
        // @UNRESOLVED: Removal of password
        password: "P@55w0rd",
        userRoleRequest: data.userRoleRequest.map((roleRequest) => ({
            id: roleRequest.id,
        })),
        srmsUserId: user.staffId,
        department: user.department?.departmentId,
        departmentName: user.department?.departmentName,
        faculty: user.department?.faculty?.facultyId,
        facultyName: user.department?.faculty?.facultyName,
        departmentAbbreviation: user.department?.shortName,
        facultyAbbreviation: user.department?.faculty?.abbreviation,
        unit: data.unit,
    }));
}

function getUserCreatedMsg(newUsersReq) {
    const isPluralUsers = newUsersReq.users.length > 1;
    const subject = isPluralUsers ? "Users" : "User";
    const verb = isPluralUsers ? "have" : "has";
    const objectPhrase = isPluralUsers ? "all the users" : "the user's email";
    return `${subject} ${verb} been created successfully. An account confirmation mail will be sent to ${objectPhrase} `;
}

function getUserUpdatedMsg() {
    return "User has been updated successfully";
}

export interface PasswordUpdateRequest {
    old_password: string;
    new_password: string;
}

export interface StaffParams {
    "search-word"?: string;
    limit?: number;
    offset?: number;
    unit?: string;
}

export interface SearchSrmsStaffParams {
    "search-key": string;
}

export interface NewUserRequest {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    userRoleRequest: Array<{
        id: string;
    }>;
    srmsUserId: string;
    department: string;
    departmentId: string;
    faculty: string;
    facultyId: string;
    facultyAbbreviation?: string;
    departmentAbbreviation?: string;
    unit: string;
}

export interface UpdateUserRequest extends Partial<NewUserRequest> {
    id: string;
}

export interface UmsUser {
    contacts: Contact[];
    first_name: string;
    id: string;
    last_name: string;
    other_name: string;
    roles: UserRole[];
    username: string;
    verified: boolean;
}

type UmsUserFieldsNotInProfile = "first_name" | "last_name" | "other_name";
export interface UserProfile extends Exclude<UmsUser, UmsUserFieldsNotInProfile> {
    firstName: string;
    lastName: string;
    otherName: string;
    organization: string;
    unit: string;
    department: Department;
    faculty: Faculty;
}

interface Contact {
    contact: string;
    verified: boolean;
    contactType?: ContactType;
}

interface UserRole {
    admin_level: string;
    role: RoleEntity;
}

interface RoleEntity {
    description: string;
    id: string;
    name: string;
    privileges: UserPrivilege[];
}

interface UserPrivilege {
    dateCreated: string;
    description: string;
    id: number;
    name: string;
}

export interface SrmsStaffResponse {
    department: Department;
    firstName: string;
    lastName: string;
    otherNames: string;
    staffId: string;
    phone: string;
    email: string;
    pictureUrl: string;
}

export interface SrmsStaff extends SrmsStaffResponse {
    fullName: string;
}

export interface Staff {
    createdAt: string;
    active: boolean;
    department: string;
    faculty: string;
    firstName: string;
    id: number;
    lastName: string;
    organization: string;
    srmsStaffId: string;
    unit: string;
    umsId: string;
    updatedAt: string;
    username: string;
}

export interface StaleDepartment {
    departmentId: string;
    departmentName: string;
    faculty: StaleFaculty;
    shortName: string;
}

export interface StaleFaculty {
    abbreviation: string;
    facultyCategory: string;
    facultyId: string;
    facultyName: string;
}

export interface Department {
    abbreviation: string;
    id: string;
    name: string;
    updatedAt: string;
    createdAt: string;
    faculty: Faculty;
}

export interface Faculty {
    abbreviation: string;
    id: string;
    name: string;
    updatedAt: string;
    createdAt: string;
    organizationId: string;
}

export const {
    useGetStaffQuery,
    useActivateStaffMutation,
    useDeactivateStaffMutation,
    useUpdatePasswordMutation,
    useGetUserQuery,
    useSearchSrmsStaffsQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
} = usersApi;
