import { Box, Text, Spacer, Select, Avatar, Button, Grid } from "@flexisaf/flexibull2";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { HookForm as Form, PageLayout, Section, WithLoader } from "@/features/ui";
import { unitsOptions } from "@/utils/ui-options";
import { titleCase } from "@/utils/string-utils";

import { useUserProfile } from "./use-user-profile";
import { SelectedRole } from "../selected-role";
import { PasswordUpdateModal } from "./password-update-modal";
import { Unit } from "@/utils/enums";
import { useResponsiveValue } from "@/lib/use-responsive-value";
import { theme } from "@/style";
import { Privileges, usePrivileges } from "@/features/authorization";
import { useAuth } from "@/features/auth";

type UserProfileProps = {
    isSelf?: boolean;
};

export function UserProfile(props: UserProfileProps) {
    const { isSelf } = props;
    const auth = useAuth();
    const [isShowingPasswordModal, togglePasswordModal] = useState(false);
    const navigate = useNavigate();
    const { form, roles, user, formValues, loading, actions, errors } = useUserProfile();
    const { hasPrivilege } = usePrivileges();
    const fullName = user ? `${user.firstName} ${user.lastName}` : "";

    const showFacultyField = [Unit.Department, Unit.Faculty].includes(user?.unit as Unit);
    const showDepartmentField = user?.unit === Unit.Department;

    const departmentName = user?.department?.name;
    const facultyName = user?.faculty ? user.faculty?.name : user?.department?.faculty.name;
    const isSecretariateUser = auth?.user?.unit === Unit.Secretariat;

    const canUpdateUser = hasPrivilege(Privileges.UpdateUsers);
    const showUpdateButton = user && (isSelf ?? canUpdateUser);

    const avatarDimension = useResponsiveValue({ sm: "120px", md: "200px" }) as string;
    return (
        <PageLayout minHeight={"98vh"} pageLabel="User Profile">
            <Box>
                <WithLoader isLoading={loading.isLoading} error={errors.userError} height="70vh">
                    <Grid sm="1fr" default="1fr 1.3fr" md="1fr 1.3fr" lr="1fr 1.3fr" gap="24px">
                        <Section style={{ height: "max-content" }}>
                            <Box style={{ textAlign: "center" }}>
                                <Text bold size="1.5em" onClick={() => navigate(-1)}>
                                    User Profile
                                </Text>
                                <Spacer space={32} />
                                <Avatar
                                    src={""}
                                    name={fullName}
                                    round
                                    style={{ width: avatarDimension, height: avatarDimension }}
                                />
                                <Spacer space={32} />
                            </Box>

                            {isSelf && (
                                <>
                                    <PasswordUpdateModal
                                        isOpen={isShowingPasswordModal}
                                        onClose={() => togglePasswordModal(false)}
                                    />

                                    <Box display="flex" style={{ justifyContent: "center" }}>
                                        <Button plain onClick={togglePasswordModal}>
                                            I want to change my password
                                        </Button>
                                    </Box>
                                    <Spacer space={16} />
                                </>
                            )}
                        </Section>

                        <Section>
                            <Form form={form}>
                                <Box
                                    display="flex"
                                    style={{ flexDirection: "column", gap: "8px", paddingTop: 16 }}
                                >
                                    <Form.Input
                                        disabled
                                        name="name"
                                        label="Name"
                                        spaceBottom
                                        defaultValue={fullName}
                                    />
                                    <Form.Input
                                        disabled
                                        name="username"
                                        defaultValue={user?.username}
                                        label="Email (Username)"
                                        spaceBottom
                                    />
                                    {showDepartmentField && (
                                        <Form.Input
                                            disabled
                                            defaultValue={departmentName}
                                            name="department"
                                            label="Department"
                                            spaceBottom
                                        />
                                    )}

                                    {showFacultyField && (
                                        <Form.Input
                                            disabled
                                            name="faculty"
                                            label="Faculty"
                                            defaultValue={facultyName}
                                            spaceBottom
                                        />
                                    )}
                                    <Form.Select
                                        name="unit"
                                        label="Unit"
                                        disable={!isSecretariateUser}
                                        defaultValue={user?.unit}
                                        options={unitsOptions}
                                        spaceBottom
                                    />
                                </Box>

                                <Spacer space={16} />
                                {isSecretariateUser ? (
                                    <>
                                        <Box>
                                            <Select
                                                label="Add User Roles"
                                                placeholder="Add new roles to user..."
                                                options={roles}
                                                onChange={actions.addRole}
                                                getOptionLabel={({ name }) => titleCase(name)}
                                                value={null}
                                                isLoading={loading.isLoadingRoles}
                                                style={{ width: "100%" }}
                                            />
                                        </Box>
                                        <Spacer space={16} />
                                    </>
                                ) : (
                                    <Text bold blcok>
                                        Role:
                                    </Text>
                                )}

                                {formValues.userRoleRequest?.map((role) => (
                                    <SelectedRole
                                        key={role.id}
                                        role={role}
                                        onDelete={
                                            isSecretariateUser ? actions.removeRole : undefined
                                        }
                                    />
                                ))}
                            </Form>
                            <Spacer space={16} />
                            <Box display="flex" style={{ justifyContent: "space-between" }}>
                                <Button
                                    pale
                                    onClick={() => navigate(-1)}
                                    spaceRight
                                    width={theme.ButtonDesktopWidth}
                                >
                                    Back
                                </Button>

                                {showUpdateButton && (
                                    <Button
                                        progress={loading.isUpdatingUser}
                                        onClick={form.handleSubmit(actions.updateUser)}
                                        width={theme.ButtonDesktopWidth}
                                    >
                                        {loading.isUpdatingUser ? "Updating..." : "Update User"}{" "}
                                    </Button>
                                )}
                            </Box>
                        </Section>
                    </Grid>
                </WithLoader>
            </Box>
        </PageLayout>
    );
}
