import { useMemo, createContext, useContext, useState, useCallback } from "react";
import { Box, Text, Spacer, Button } from "@flexisaf/flexibull2";
import SwipeableViews from "react-swipeable-views";
import noop from "lodash/noop";

import { AuthUser, useAuth } from "@/features/auth";
import { FileTypes, Unit } from "@/utils/enums";
import { ApiError } from "@/types/common";
import { theme } from "@/style";

import { PlainView, DepartmentGroupedView, FacultyGroupedView } from "./agenda-view-modes";
import { AgendaDetails } from "../agenda-api";
import { DataItem, Icon, Modal, WithLoader } from "@/features/ui";
import { DocumentPreviewer } from "@/features/documents";
import { hasItems } from "@/utils/object-utils";
import { Prayer } from "@/features/prayers";
import { PrayerViewer } from "@/features/prayers/prayers/prayer-viewer.component";
import { useResponsiveValue } from "@/lib/use-responsive-value";

type AgendaViewerProps = {
    agenda: AgendaDetails;
    isLoading: boolean;
    isUpdating: boolean;
    error?: ApiError;
    showPrayerItemStatus?: boolean;
};

type AgendaContextValues = {
    agenda: AgendaDetails;
    isUpdating: boolean;
    user: AuthUser;
    showPrayerItemStatus: boolean;
    hasAgendaItems: boolean;
    onSelectPrayer: (prayer: Prayer) => void;
    setPrayerItemsView: () => void;
    setAgendaItemsView: () => void;
};

const AgendaContext = createContext({} as AgendaContextValues);
export const useAgendaContext = () => useContext(AgendaContext);

enum ViewIndices {
    AgendaItems = 0,
    PrayerItems = 1,
}
export default function AgendaViewer(props: AgendaViewerProps) {
    const { agenda, isLoading, isUpdating, error, showPrayerItemStatus = false } = props;
    const { user } = useAuth();
    const [viewIndex, setViewIndex] = useState<number>(
        !agenda?.agendaItems?.length ? ViewIndices.PrayerItems : ViewIndices.AgendaItems
    );

    const [currentAttachmentUrl, setCurrentAttachmentUrl] = useState<string | null>(null);
    const [selectedPrayer, setSelectedPrayer] = useState<Prayer | null>(null);
    const prayerViewerModalWidth = useResponsiveValue({ sm: "90vw", md: "600px" }) as string;

    const content = useMemo(() => {
        if ([Unit.Secretariat, Unit.Secretariat, Unit.Sbc].includes(user.unit)) {
            return <FacultyGroupedView />;
        }
        if (user.unit === Unit.Faculty) {
            return <DepartmentGroupedView />;
        }
        return <PlainView />;
    }, [user]);

    const agendaItemsList = useMemo(
        () =>
            agenda?.agendaItems.map((item) => {
                let rightContent: React.ReactNode = null;

                if (item.documentUrl) {
                    rightContent = (
                        <Button
                            plain
                            onClick={() => setCurrentAttachmentUrl(item.documentUrl as string)}
                            color={theme.PrimaryColor}
                            iconLeft={<Icon icon="document-download" color={theme.PrimaryColor} />}
                        >
                            <Text style={{ fontSize: theme.FontSizes.sm }}>View Attachment</Text>
                        </Button>
                    );
                }
                const containerPadding = rightContent ? "8px" : "16px";
                return (
                    <>
                        <DataItem
                            onClick={noop}
                            style={{ padding: containerPadding }}
                            key={item.id}
                            rightContent={rightContent}
                            subtitle={item.title}
                        />
                        <Spacer space={8} />
                    </>
                );
            }),
        [agenda?.agendaItems, setCurrentAttachmentUrl]
    );

    const documentViewerModal = (
        <Modal
            isOpen={!!currentAttachmentUrl}
            onClose={() => setCurrentAttachmentUrl(null)}
            style={{ width: "max-content" }}
            bodyStyle={{ padding: "0px" }}
        >
            <DocumentPreviewer url={currentAttachmentUrl as string} ext={FileTypes.Pdf} />
        </Modal>
    );

    const maybePrayerViewerModal = selectedPrayer && (
        <Modal
            isOpen={!!selectedPrayer}
            onClose={() => setSelectedPrayer(null)}
            style={{ width: prayerViewerModalWidth }}
            bodyStyle={{ padding: "0px" }}
        >
            <PrayerViewer prayer={selectedPrayer} onBack={() => setSelectedPrayer(null)} />
        </Modal>
    );

    const setAgendaItemsView = useCallback(
        () => setViewIndex(ViewIndices.AgendaItems),
        [setViewIndex]
    );
    const setPrayerItemsView = useCallback(
        () => setViewIndex(ViewIndices.PrayerItems),
        [setViewIndex]
    );
    const onSelectPrayer = (prayer: Prayer) => setSelectedPrayer(prayer);
    const hasAgendaItems = hasItems(agenda?.agendaItems);
    const noOfPrayerItems = agenda?.prayerItems.length;

    return (
        <AgendaContext.Provider
            value={{
                agenda,
                isUpdating,
                user,
                showPrayerItemStatus,
                setPrayerItemsView,
                setAgendaItemsView,
                onSelectPrayer,
                hasAgendaItems,
            }}
        >
            <WithLoader isLoading={isLoading} error={error}>
                <>
                    {/* Modals */}
                    {documentViewerModal}
                    {maybePrayerViewerModal}

                    {/* Content */}
                    <SwipeableViews index={viewIndex} onChangeIndex={setViewIndex} disabled={true}>
                        <Box>
                            <Text block>Agenda Items</Text>
                            <hr color={theme.SecondaryGrey} />
                            <Spacer space={8} />
                            {agendaItemsList}
                            <Spacer space={8} />
                            <hr color={theme.SecondaryGrey} />
                            <DataItem
                                style={{ padding: "8px" }}
                                subtitle="Prayer Items"
                                onClick={setPrayerItemsView}
                                caption={`${noOfPrayerItems} prayers`}
                                rightContent={<Icon icon="arrow-right-plain" />}
                            />
                        </Box>
                        <Box>{content}</Box>
                    </SwipeableViews>
                </>
            </WithLoader>
        </AgendaContext.Provider>
    );
}
