import { IconsaxIcon } from "@/assets/iconsax";
import { theme } from "@/style";
import { Box } from "@flexisaf/flexibull2";
import { Icon } from "./icon";

type LabelWithIconProps = {
    icon: IconsaxIcon;
    text: string;
};

export const LabelWithIcon = ({ icon, text }: LabelWithIconProps) => {
    return (
        <Box display="flex" style={{ alignItems: "center", color: theme.PrimaryGrey }}>
            <Box style={{ marginRight: "4px" }}>
                <Icon icon={icon} color={theme.PrimaryGrey} />
            </Box>
            <Box>{text}</Box>
        </Box>
    );
};
