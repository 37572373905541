import { useQueryStateSync } from "@/lib/use-query-state-sync";
import { PaginationAdapterReturn, paginationAdapter } from "@/lib/pagination-adapter";
import { ApiError } from "@/types/common";
import { useGetUserMeeeting } from "@/features/meetings";

import { AgendaParams, useGetAgendasQuery } from "../agenda-api";
import { MeetingStatus, Unit } from "@/utils/enums";
import { useAuth } from "@/features/auth";
import { useUserUnitValue } from "@/features/users";

export function useAgendas() {
    const { user } = useAuth();
    const unitParam = useUserUnitValue({ SECRETARIAT: Unit.Senate, default: user.unit });
    const queryStateSync = useQueryStateSync<AgendaParams, PaginationAdapterReturn>(
        {
            limit: 10,
            meetingUnit: unitParam,
            departmentId: user.department?.id,
            facultyId: user.faculty?.id,
        },
        paginationAdapter
    );

    const { queryState, setQueryField, page, setPage, setLimit } = queryStateSync;
    const { data: agendasData, ...getAgendasMeta } = useGetAgendasQuery(queryState);
    const { meeting: upcomingMeeting, ...getUserUpcomingMeetingMeta } = useGetUserMeeeting({
        status: MeetingStatus.Inactive,
    });
    const { data: upcomingMeetingAgendaData, ...upcomingMeetingAgendaMeta } = useGetAgendasQuery(
        { meetingId: upcomingMeeting?.id },
        { skip: !upcomingMeeting?.id }
    );

    const upcomingMeetingAgenda = upcomingMeetingAgendaData?.entities[0];

    const filterByMeeting = (meetingTitle: string) => setQueryField("meetingTitle", meetingTitle);
    const filterByDate = (val: string) => setQueryField("meetingDate", new Date(val).getTime());

    const loading = {
        isLoadingAgendas: getAgendasMeta.isLoading,
        isUpdatingAgendas: getAgendasMeta.isFetching,
        isLoadingUpcomingMeeting: getUserUpcomingMeetingMeta.isLoading,
        isLoadingUpcomingMeetingAgenda: upcomingMeetingAgendaMeta.isLoading,
    };

    const errors = {
        usersError: <ApiError>getAgendasMeta.error,
        getUpcomingMeetingError: <ApiError>getUserUpcomingMeetingMeta.error,
    };

    const actions = {
        setQueryField,
        filterByDate,
        filterByMeeting,
        setPage,
        setLimit,
    };

    return {
        upcomingMeeting,
        upcomingMeetingAgenda,
        page,
        queryState,
        agendasData,
        loading,
        errors,
        actions,
    };
}
