import { useState, useEffect } from "react";

type IntervalFunctions = {
    start: () => void;
    stop: () => void;
    elapsedTime: number;
    running: boolean;
};

export const useOngoing = (): IntervalFunctions => {
    const [running, setRunning] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);

    const startInterval = () => {
        if (!running) {
            setRunning(true);
        }
    };

    const stopInterval = () => {
        if (running) {
            setRunning(false);
        }
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (running) {
            intervalId = setInterval(() => {
                // intervalCallback();
                setElapsedTime((prevElapsedTime) => prevElapsedTime + 1000);
            }, 1000);
        } else if (!running && intervalId !== null) {
            clearInterval(intervalId);
        }

        return () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
        };
    }, [running]);

    return {
        start: startInterval,
        stop: stopInterval,
        elapsedTime,
        running,
    };
};

type TimeInterval = string;

function generateTimeInterval(startTime: string): TimeInterval {
    const startTimestamp = new Date(startTime).getTime();
    const currentTimestamp = Date.now();
    const timeDifference = currentTimestamp - startTimestamp;

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${hours}hr:${minutes}min:${seconds}s`;
}

function useTimeInterval(startTime: string): TimeInterval {
    const [timeInterval, setTimeInterval] = useState<TimeInterval>(generateTimeInterval(startTime));

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeInterval = generateTimeInterval(startTime);
            setTimeInterval(newTimeInterval);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [startTime]);

    return timeInterval;
}

export default useTimeInterval;
