export enum Methods {
    Post = "post",
    Patch = "patch",
    Get = "GET",
    Put = "PUT",
    Delete = "DELETE",
    Options = "OPTIONS",
}

export enum Environments {
    Development = "development",
    Staging = "staging",
    Production = "production",
}

export enum UserTypes {
    Staff = "STAFF",
    Student = "STUDENT",
}

export enum FileTypes {
    Pdf = "application/pdf",
    Json = "application/json",
    Document = "application/docx",
    Image = "image/jpeg",
}

export enum CacheTime {
    VeryShort = 2000, // 2m
    Short = 5000, // 5m
    Medium = 15000, // 15m
    Long = 30000, // 30m
}

export enum SortDirection {
    Asc = "asc",
    Desc = "desc",
}

export enum Duration {
    Short = 400,
    Mid = 800,
    Long = 1600,
}
export enum ContactType {
    Email = "EMAIL",
    PhoneNumber = "PHONE_NUMBER",
}

export enum HttpStatusCode {
    OK = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    RequestTimeout = 408,
    Conflict = 409,
    Gone = 410,
    PreconditionFailed = 412,
    PayloadTooLarge = 413,
    TooManyRequests = 429,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
}

export enum Unit {
    Department = "DEPARTMENT",
    Faculty = "FACULTY",
    Sbc = "SBC",
    Secretariat = "SECRETARIAT",
    Senate = "SENATE",
}

export enum PrayerStatuses {
    Recommended = "RECOMMENDED",
    SteppedDown = "STEPPED_DOWN",
    Initiated = "INITIATED",
    Raised = "RAISED",
    Approved = "APPROVED",
    ConditionalApproval = "CONDITIONAL_APPROVAL",
    Submitted = "SUBMITTED",
    Rejected = "REJECTED",
}

export enum MeetingStatus {
    Active = "ACTIVE",
    Inactive = "INACTIVE",
    Completed = "COMPLETED",
}

export enum PrayerCategories {
    Results = "RESULTS",
    Deferment = "DEFERMENT",
    Deregistration_of_Courses = "DEREGISTRATION_OF_COURSES",
    Extension_of_Residency = "EXTENSION_OF_RESIDENCY",
    Others = "OTHERS",
}

export enum ViewportWidth {
    sm = 600,
    md = 900,
    lg = 1440,
    xl = 1920,
}
