/* eslint-disable */
import { useEffect, useState } from "react";
import { MonitorRecorder } from "iconsax-react";
import { useParams } from "react-router-dom";

import { Box, Spacer, Modal } from "@flexisaf/flexibull2";
import { Button } from "@flexisaf/flexibull2/build/button";
import { Text } from "@flexisaf/flexibull2/build/typo";

import theme from "@/style/theme";

import { TabButton, TabContainer, TabPanel } from "@/features/ui/tab.component";
import { Title } from "../ui/menu";
import { useLocation, useToggle } from "react-use";
import {
    useAddAttendanceMutation,
    useGetAttendeeQuery,
    useGetMeetingDetailsQuery,
    useGetMeetingMinuteQuery,
} from "./meeting.api";
import { useMeeting } from "./meeting.controller";
import useTimeInterval, { useOngoing } from "./ongoing";
import { appPaths } from "@/app";
import { useAuth, useAuthUserNavigate } from "../auth";

import { AgendaTab } from "./meeting-tabs";
import { Privileges, usePrivileges } from "../authorization";
import { MeetingWrapper, StyledModalBody } from "@/features/ui/meeting.style";
import { useQueryStateSync } from "@/lib/use-query-state-sync";
import { MeetingStatus } from "@/utils/enums";
import { useGetUserMeeeting } from "@/features/meetings";
import { PageLayout } from "@/features/ui";
import { Attendance } from "./attendance";
import { MeetingRoom } from "./meeting-room";
import { MeetingMinute } from "./meeting-minute";
import { DocumentPreviewerModal } from "../documents";

export const OngoingMeeting = () => {
    const location = useLocation();
    const { id } = useParams();
    const { navigateTo } = useAuthUserNavigate();
    const { stop } = useOngoing();
    const { hasPrivilege } = usePrivileges();
    const { user } = useAuth();
    const { meeting: ongoingMeeting } = useGetUserMeeeting({ status: MeetingStatus.Active });
    const timeInterval = useTimeInterval(ongoingMeeting?.startTime as string);
    // @ts-expect-error
    const meetId = location?.pathname.substring(location?.pathname.lastIndexOf("/") + 1);
    const { data } = useGetMeetingDetailsQuery(meetId);
    const [open, setOpen] = useState(false);
    const [minuteFile, setMinuteFile] = useState<File>();
    const { endMeeting, stoppingMeeting, successfullyStopped } = useMeeting();

    const { data: minuteData } = useGetMeetingMinuteQuery(id ?? "");
    const [isPreviewerOpen, setIsPreviewerOpen] = useToggle(false);

    useEffect(() => {
        if (successfullyStopped) {
            stop();
            setOpen(false);
            navigateTo(appPaths.dashboard);
        }
    }, [navigateTo, stop, successfullyStopped]);

    const stopOngoingMeeting = () => {
        const payload = { id: data?.id, duration: timeInterval };
        endMeeting(payload);
    };

    const { queryState, setQueryField } = useQueryStateSync<{ activeTab: number }>({
        activeTab: 0,
    });

    const handleTabClick = (index: number) => {
        setQueryField("activeTab", index);
    };
    const currentlyActiveTab = queryState?.activeTab ? +queryState.activeTab : 0;

    const queryParams = {
        userId: user.id,
        meetingId: Number(meetId),
    };

    const { data: userAsAttendee, isError } = useGetAttendeeQuery(queryParams);

    const [addAttendee] = useAddAttendanceMutation();

    // useEffect(() => {
    //     const payload = {
    //         meetingId: meetId,
    //         attendees: [user.id],
    //     };
    //     if (meetId && user.id) {
    //         if (userAsAttendee === undefined && isError) {
    //             addAttendee(payload);
    //         }
    //     }
    // }, [addAttendee, isError, meetId, user.id, userAsAttendee]);

    return (
        <>
            <PageLayout>
                <MeetingWrapper>
                    <Box
                        display="flex"
                        style={{ justifyContent: "space-between", alignItems: "flex-end" }}
                    >
                        <Box>
                            <Title
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                }}
                            >
                                {data?.title}
                            </Title>
                            <Spacer space="5px" />
                            <Text color={theme.PrimaryGreyMid} size="12px" block bold>
                                Duration
                            </Text>
                            <Spacer space="5px" />
                            <Text size="16px" color={theme.PrimaryGreyMid} bold>
                                <MonitorRecorder size="15" /> {"  "}
                                {timeInterval}
                            </Text>
                        </Box>
                        <Box display="flex">
                            <Button
                                disabled={!hasPrivilege(Privileges.CreateMeeting)}
                                style={{
                                    width: "120px",
                                }}
                                color="#FD151B"
                                onClick={() => setOpen(true)}
                            >
                                Stop Meeting
                            </Button>
                        </Box>
                    </Box>
                    <Spacer space={20} />
                    <TabContainer>
                        <TabButton
                            active={currentlyActiveTab === 0}
                            onClick={() => handleTabClick(0)}
                        >
                            Meeting Room
                        </TabButton>
                        <TabButton
                            active={currentlyActiveTab === 1}
                            onClick={() => handleTabClick(1)}
                        >
                            Attendance
                        </TabButton>
                        <TabButton
                            active={currentlyActiveTab === 2}
                            onClick={() => handleTabClick(2)}
                        >
                            Minutes
                        </TabButton>
                        <TabButton
                            active={currentlyActiveTab === 3}
                            onClick={() => handleTabClick(3)}
                        >
                            Agenda
                        </TabButton>
                    </TabContainer>

                    <TabPanel active={currentlyActiveTab === 0}>
                        <MeetingRoom meetId={Number(meetId)} />
                    </TabPanel>
                    <TabPanel active={currentlyActiveTab === 1}>
                        <Attendance meetId={Number(meetId)} />
                    </TabPanel>
                    <TabPanel active={currentlyActiveTab === 2}>
                        <MeetingMinute
                            minuteFile={minuteFile}
                            setMinuteFile={setMinuteFile}
                            setIsPreviewerOpen={setIsPreviewerOpen}
                            handleTabClick={handleTabClick}
                        />
                    </TabPanel>
                    <TabPanel active={currentlyActiveTab === 3}>
                        <AgendaTab meetingId={data?.id} />
                    </TabPanel>
                    <Modal outerclick onClose={() => setOpen(false)} open={open}>
                        <StyledModalBody>
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Box pad="0 20px">
                                    <h3>Stop Meeting? </h3>
                                </Box>
                                <Box pad="0 20px">
                                    <h4>
                                        You are about to end the meeting for everyone, please
                                        confirm your action
                                    </h4>
                                </Box>
                            </div>
                            <div
                                style={{
                                    textAlign: "center",
                                    padding: "10px 20px",
                                }}
                            >
                                <Button spaceRight pale onClick={() => setOpen(false)}>
                                    No, Go Back
                                </Button>
                                <Button
                                    style={{ background: "red" }}
                                    progress={stoppingMeeting}
                                    onClick={stopOngoingMeeting}
                                    iconRight={<i className="flexibull-angle-right" />}
                                >
                                    Yes, End Meeting
                                </Button>
                                <Spacer space={20} />
                            </div>
                        </StyledModalBody>
                    </Modal>
                </MeetingWrapper>
            </PageLayout>
            <DocumentPreviewerModal
                isOpen={isPreviewerOpen}
                onClose={() => setIsPreviewerOpen(false)}
                url={minuteData?.attachmentUrl ?? ""}
            />
        </>
    );
};
