import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { TagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { config } from "@/utils/config";
import { createBaseQuery } from "@/lib/base-query";
import { CacheTime } from "@/utils/enums";

const tagTypes = [
    "AUTH",
    "USERS",
    "MEETING",
    "PRAYERS",
    "AGENDA",
    "PRAYER",
    "COMMENTS",
    "STAFF",
] as const;

export const baseApi = createApi({
    baseQuery: createBaseQuery({ baseUrl: config.apiUrl }),
    tagTypes,
    reducerPath: "baseApi",
    endpoints: () => ({}),
    keepUnusedDataFor: CacheTime.Short,
});

export type ApiTags = ReadonlyArray<TagDescription<typeof tagTypes[number]>>;
