import { lazy, Suspense, useState } from "react";
import styled, { keyframes } from "styled-components";
import {
    Outlet,
    Navigate,
    useMatch,
    NavLink,
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";

import { Layout, Box, Loader } from "@flexisaf/flexibull2";

import { NetworkIndicator, Icon, NotFound, ProfileHolder } from "@/features/ui";
import { theme } from "@/style";
import { Meeting, OngoingMeeting, useGetUserMeeeting, Welcome } from "@/features/meetings";
import {
    Prayers,
    PrayerDetails,
    PrayerComments,
    PrayerCreate,
    PrayerEdit,
} from "@/features/prayers";
import { UserRole } from "@/features/ui/menu";
import { NewUser, UserProfile } from "@/features/users";
import { Spacer } from "@flexisaf/flexibull2/build/layout";
import { appPaths } from "./app-paths";
import {
    AccountVerification,
    AuthGuard,
    AuthProvider,
    ForgotPassword,
    Login,
    PasswordReset,
    useAuth,
    useAuthUserNavigate,
} from "@/features/auth";

import { Breadcrumbs } from "@/features/ui/base/breadcrumb";
import { PrivilegeGuard, Privileges, RoleSwitcher } from "@/features/authorization";
import { NewMeet } from "@/features/meetings/new-meeting";
import UpdateAgenda from "@/features/agenda/update-agenda/update-agenda";
import { PreviousMeeting } from "@/features/meetings/previous-meeting";
import PreviousList from "@/features/meetings/previous-list";
import BaseLayout from "./app-layout";
import { titleCase } from "@/utils/string-utils";
import { MeetingStatus, ViewportWidth } from "@/utils/enums";
import { useResponsiveValue } from "@/lib/use-responsive-value";
import { MonitorRecorder } from "iconsax-react";
import useTimeInterval from "@/features/meetings/ongoing";
import Notification from "@/features/notification/notification";

const Users = lazy(async () => await import("@/features/users/users/users"));
const Agendas = lazy(async () => await import("@/features/agenda/agendas/agendas"));
const Agenda = lazy(async () => await import("@/features/agenda/agenda/agenda"));
const CreateAgenda = lazy(
    async () => await import("@/features/agenda/create-agenda/create-agenda")
);

const Sidebar = styled.div<{ isOpen: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 20px;
    box-shadow: ${({ isOpen }) => (isOpen ? "0px -5px 15px rgba(0, 0, 0, 0.3)" : "none")};
    border-top-left-radius: ${({ isOpen }) => (isOpen ? "20px" : "none")};
    border-top-right-radius: ${({ isOpen }) => (isOpen ? "20px" : "none")};
    transform: translateY(${({ isOpen }) => (isOpen ? "0%" : "100%")});
    transition: transform 0.3s ease-in-out;
    width: 100%;
    z-index: ${theme.Layer.third};

    @media screen and (min-width: ${ViewportWidth.sm}px) {
        max-height: 98vh;
        top: 0;
        bottom: 0;
        width: ${theme.SideBarDesktopWidth}px;
        transform: translateX(0%);
        transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
        box-shadow: none;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0;
        max-height: 100vh;
        background: #1890ff;
        margin-right: 200px;
    }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
    &::before {
        content: "";
        display: ${({ isOpen }) => (isOpen ? "block" : "none")};
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        height: 100%;
        background-color: #2d314282;
        z-index: ${theme.Layer.third};
    }
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

const ToggleButton = styled.div<{ isOpen: boolean }>`
    flex: 1;
    margin: 0;
    width: 50px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    z-index: ${theme.Layer.third};
    display: ${({ isOpen }) => (isOpen ? "none" : "flex")};
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

const StyledMenuItem = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    margin-bottom: 6px;
    border-radius: 10px;

    &.active {
        background: rgba(7, 77, 142, 0.17);
    }

    @media screen and (min-width: ${ViewportWidth.sm}px) {
        a,
        span {
            color: white !important;
        }
    }
`;
StyledMenuItem.defaultProps = {
    className: ({ isActive }) => (isActive ? "active" : ""),
};

const IconWrapper = styled.div`
    margin-right: 10px;
`;

const Text = styled.span`
    color: #2d3142;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 140% !important;
    text-align: center;
    margin-bottom: 5px;
`;

const StyledDiv = styled.div`
    margin: 30px 0 0 0;

    @media (min-width: 768px) {
        margin: 30px 0 0 0;
    }
`;

const codeSplittingloadingIndicatorScreen = (
    <Box
        display="flex"
        style={{ justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh" }}
    >
        <Loader speed="fast" />
    </Box>
);

const pulsateAnimation = keyframes`
 0% {
    background-color: #1890ff; 
  }
  50% {
    background-color: #002a52; 
  }
  100% {
    background-color:  #1890ff; 
  }
`;

export const StyledTopBar = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    height: 68px;
    font-size: 18px;
    padding: 15px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    flex: 1;
    z-index: 9999;
    justify-content: space-between;
    align-items: center;
    background-color: #2d3142;
    transition: background-color 0.3s, box-shadow 0.3s;
    animation: ${pulsateAnimation} 3s infinite;

    @media (min-width: ${ViewportWidth.sm}px) {
        position: fixed;
        width: auto;
        left: ${theme.SideBarDesktopWidth}px;
        background-color: ${theme.PrimaryColor};
        transition: background-color 0.3s, box-shadow 0.3s;
        animation: ${pulsateAnimation} 4s infinite;

        .toggle-button {
            display: none;
        }

        &:hover {
            background-color: #1890ff;
        }
    }
`;

const Header = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - ${theme.SideBarDesktopWidth}px);
    height: 65px;
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: white;
    box-shadow: 0 1px 1px rgba(69, 69, 69, 0.1);
    z-index: ${theme.Layer.second};
    margin-bottom: 100px;

    flex: 1;
    margin-left: ${theme.SideBarDesktopWidth}px;
    @media screen and (max-width: 768px) {
        margin-left: 0;
        width: 100%;
    }
`;

const ButtonCover = styled.div`
    width: 37px;
    height: 36px;
    padding: 0px 10px 0px 10px;
    border-radius: 5px;
    display: grid;
    place-items: center;
    background: #1890ff;
`;

const SideBar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { buildPath, navigateTo } = useAuthUserNavigate();
    const { user, logout } = useAuth();
    const { meeting: ongoingMeeting } = useGetUserMeeeting({ status: MeetingStatus.Active });

    const ongoingMeetingRouteMatch = useMatch(buildPath(appPaths.getOngoingMeeting()) as string);
    const showOngingMeetingHeader = Boolean(ongoingMeeting && !ongoingMeetingRouteMatch);
    const hasMoreThanOneRole = user?.roles?.length > 1;
    const timeInterval = useTimeInterval(ongoingMeeting?.startTime as string);

    const handleToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const iconColor = useResponsiveValue({ sm: "auto", md: "white" }) as string;
    const isMobile = useResponsiveValue({ sm: true, md: false, lg: false }) as boolean;

    const toggleButton = (
        <ToggleButton isOpen={isModalOpen} onClick={handleToggleModal}>
            <div>
                <Icon icon="hamburger" />
            </div>
        </ToggleButton>
    );

    const userFullName = user ? `${user.firstName} ${user.lastName}` : "";

    const userProfile = (
        <ProfileHolder
            username={user?.username ?? ""}
            fullName={userFullName}
            menuList={[{ label: "Logout", onClick: logout }]}
        />
    );

    return (
        <>
            <Overlay isOpen={isModalOpen} onClick={handleToggleModal} />
            <Header>
                {isMobile ? toggleButton : <Breadcrumbs />}
                <div> </div>
                {!isMobile && <Box>{user && userProfile}</Box>}
                {isMobile && (
                    <div>
                        <div>
                            <Icon icon="notification" />
                        </div>
                    </div>
                )}
                {showOngingMeetingHeader && (
                    <StyledTopBar>
                        <div
                            style={{
                                marginLeft: "20px",
                                flexGrow: 1,
                            }}
                        >
                            <div> {ongoingMeeting?.title?.toUpperCase()} - ONGOING</div>
                            <Text
                                style={{
                                    color: "whitesmoke",
                                    fontSize: theme.FontSizes.sm,
                                }}
                            >
                                <MonitorRecorder size="15" /> {"  "} {timeInterval}
                            </Text>
                        </div>

                        <div>
                            <ButtonCover
                                onClick={() =>
                                    navigateTo(
                                        appPaths.getOngoingMeeting(String(ongoingMeeting?.id))
                                    )
                                }
                            >
                                <Icon icon="arrow-right-plain" color="white" size={20} />
                            </ButtonCover>
                        </div>
                    </StyledTopBar>
                )}
            </Header>
            <Sidebar isOpen={isModalOpen}>
                <UserRole>{titleCase(user.currentRole.role.name)}</UserRole>
                <Spacer size="24px" />

                <StyledMenuItem
                    to={buildPath(appPaths.dashboard) as string}
                    onClick={handleToggleModal}
                >
                    <IconWrapper>
                        <Icon icon="speedometer" color={iconColor} />
                    </IconWrapper>
                    <Text>Dashboard</Text>
                </StyledMenuItem>

                <StyledMenuItem
                    to={buildPath(appPaths.previousMeeting) as string}
                    onClick={handleToggleModal}
                >
                    <IconWrapper>
                        <Icon icon="monitor-recorder" color={iconColor} />
                    </IconWrapper>
                    <Text>Previous Meetings</Text>
                </StyledMenuItem>
                <StyledMenuItem
                    to={buildPath(appPaths.notification) as string}
                    onClick={handleToggleModal}
                >
                    <IconWrapper>
                        <Icon icon="notification" color={iconColor} />
                    </IconWrapper>
                    <Text>Notification</Text>
                </StyledMenuItem>

                <PrivilegeGuard privileges={Privileges.ReadPrayers}>
                    <StyledMenuItem
                        to={buildPath(appPaths.prayers) as string}
                        onClick={handleToggleModal}
                    >
                        <IconWrapper>
                            <Icon icon="book-saved" color={iconColor} />
                        </IconWrapper>
                        <Text>Prayers</Text>
                    </StyledMenuItem>
                </PrivilegeGuard>

                <PrivilegeGuard privileges={Privileges.ReadAgenda}>
                    <StyledMenuItem
                        to={buildPath(appPaths.agendas) as string}
                        onClick={handleToggleModal}
                    >
                        <IconWrapper>
                            <Icon icon="clipboard" color={iconColor} />
                        </IconWrapper>
                        <Text>Agenda</Text>
                    </StyledMenuItem>
                </PrivilegeGuard>

                <PrivilegeGuard privileges={Privileges.ReadUsers}>
                    <StyledMenuItem
                        to={buildPath(appPaths.users) as string}
                        onClick={handleToggleModal}
                    >
                        <IconWrapper>
                            <Icon icon="people" color={iconColor} />
                        </IconWrapper>
                        <Text>Users</Text>
                    </StyledMenuItem>
                </PrivilegeGuard>

                <StyledMenuItem
                    to={buildPath(appPaths.getMyProfile(user?.id)) as string}
                    onClick={handleToggleModal}
                >
                    <IconWrapper>
                        <Icon icon="user" color={iconColor} />
                    </IconWrapper>
                    <Text>My profile</Text>
                </StyledMenuItem>

                {hasMoreThanOneRole && <RoleSwitcher />}

                <StyledMenuItem to="" onClick={logout}>
                    <IconWrapper>
                        <Icon icon="logout" color={iconColor} />
                    </IconWrapper>
                    <Text>Logout</Text>
                </StyledMenuItem>
            </Sidebar>

            <Spacer space={40} />
        </>
    );
};

const routes = [
    {
        path: "/",
        element: (
            <AuthProvider>
                <Layout theme={theme}>
                    <Outlet />
                </Layout>
            </AuthProvider>
        ),
        children: [
            { path: "/", element: <Navigate to={appPaths.login} /> },
            { path: appPaths.login, element: <Login /> },
            { path: appPaths.forgotPassword, element: <ForgotPassword /> },
            { path: appPaths.passwordReset, element: <PasswordReset /> },
            { path: appPaths.accountVerification, element: <AccountVerification /> },
            {
                element: (
                    <AuthGuard>
                        <>
                            <BaseLayout>
                                <StyledDiv>
                                    <SideBar />
                                    <Outlet />
                                </StyledDiv>
                            </BaseLayout>
                            <NetworkIndicator />
                        </>
                    </AuthGuard>
                ),
                children: [
                    { path: appPaths.dashboard, element: <Welcome /> },
                    {
                        path: appPaths.newMeetings,
                        element: (
                            <PrivilegeGuard
                                element={<NewMeet />}
                                privileges={Privileges.CreateMeeting}
                                fallback
                            />
                        ),
                        handle: {
                            crumb: () => ({
                                to: appPaths.newMeetings,
                                title: "New Meeting",
                            }),
                        },
                    },
                    {
                        path: appPaths.getMeeting(),
                        element: (
                            <PrivilegeGuard
                                element={<Meeting />}
                                privileges={Privileges.ReadMeeting}
                                fallback
                            />
                        ),
                    },
                    {
                        path: appPaths.getPastMeeting(),
                        element: (
                            <PrivilegeGuard
                                element={<PreviousMeeting />}
                                privileges={Privileges.ReadMeeting}
                                fallback
                            />
                        ),
                        handle: {
                            crumb: () => ({
                                to: appPaths.getPastMeeting(),
                                title: "Previous Meetings",
                            }),
                        },
                    },
                    {
                        path: appPaths.getOngoingMeeting(),
                        element: (
                            <PrivilegeGuard
                                element={<OngoingMeeting />}
                                privileges={Privileges.ReadMeeting}
                                fallback
                            />
                        ),
                    },

                    {
                        path: appPaths.prayers,
                        element: (
                            <PrivilegeGuard
                                element={<Prayers />}
                                privileges={Privileges.ReadPrayers}
                                fallback
                            />
                        ),
                        handle: {
                            crumb: () => ({
                                to: appPaths.prayers,
                                title: "Prayers",
                            }),
                        },
                    },

                    // @unresolved: Open to all
                    { path: appPaths.getPrayerById(), element: <PrayerDetails /> },
                    {
                        path: appPaths.editPrayer(),
                        element: (
                            <PrivilegeGuard
                                element={<PrayerEdit />}
                                privileges={Privileges.UpdatePrayers}
                                fallback
                            />
                        ),
                    },
                    { path: appPaths.getPrayerComments(), element: <PrayerComments /> },
                    {
                        path: appPaths.createPrayer,
                        element: (
                            <PrivilegeGuard
                                element={<PrayerCreate />}
                                privileges={Privileges.InitiatePrayers}
                                fallback
                            />
                        ),
                        handle: {
                            crumb: () => ({
                                to: appPaths.createPrayer,
                                title: "Create Prayer",
                            }),
                        },
                    },
                    {
                        path: appPaths.users,
                        element: (
                            <PrivilegeGuard
                                privileges={Privileges.ReadUsers}
                                fallback
                                element={<Users />}
                            />
                        ),
                        children: [
                            {
                                path: appPaths.createUser,
                                element: (
                                    <PrivilegeGuard
                                        privileges={Privileges.CreateUsers}
                                        fallback
                                        element={<NewUser />}
                                    />
                                ),
                            },
                        ],
                    },
                    { path: appPaths.getUser(), element: <UserProfile /> },
                    {
                        path: appPaths.agendas,
                        element: (
                            <PrivilegeGuard
                                privileges={Privileges.ReadAgenda}
                                fallback
                                element={<Agendas />}
                            />
                        ),
                    },
                    {
                        path: appPaths.getAgenda(),
                        element: (
                            <PrivilegeGuard
                                privileges={Privileges.ReadAgenda}
                                element={<Agenda />}
                                fallback
                            />
                        ),
                    },
                    {
                        path: appPaths.createAgenda,
                        element: (
                            <PrivilegeGuard
                                privileges={Privileges.CreateAgenda}
                                fallback
                                element={<CreateAgenda />}
                            />
                        ),
                    },
                    {
                        path: appPaths.getEditAgenda(),
                        element: (
                            <PrivilegeGuard
                                privileges={Privileges.UpdateAgenda}
                                element={<UpdateAgenda />}
                            />
                        ),
                    },
                    { path: appPaths.previousMeeting, element: <PreviousList /> },
                    { path: appPaths.notification, element: <Notification /> },
                    {
                        path: appPaths.getMyProfile(),
                        element: <UserProfile isSelf={true} />,
                    },
                ],
            },
            { path: "*", element: <NotFound /> },
        ],
    },
];

const router = createBrowserRouter(routes);
export const AppRouter = () => {
    return (
        <Suspense fallback={codeSplittingloadingIndicatorScreen}>
            <RouterProvider router={router} />
        </Suspense>
    );
};
