import { Box, Spacer, Grid, Button, Text } from "@flexisaf/flexibull2";
import { useEffect, useRef } from "react";
import { useClickAway } from "react-use";

import { Modal, Select, WithLoader, SearchBar, HookForm as Form } from "@/features/ui";
import { theme } from "@/style";
import { ApiError, Mutation } from "@/types/common";

import { MAX_USER_SIZE, useUserForm } from "./use-user-form";
import { UserCard } from "../user-card";
import { SelectedRole } from "../selected-role";
import { unitsOptions } from "@/utils/ui-options";
import { hasItems } from "@/utils/object-utils";
import { snakeToTitleCase } from "@/utils/string-utils";

const cardBorder = `1px solid ${theme.SecondaryGrey}`;
const MAX_USER_SIZE_MSG = `Max users size reached. Only accepts up to ${MAX_USER_SIZE} users at a time`;

type UserFormProps = {
    title: string;
    isEdit?: boolean;
    isSubmitted: boolean;
    isSubmitting: boolean;
    isLoading?: boolean;
    loadError?: ApiError;
    onSubmit: Mutation;
    onClose: () => void;
};

export function UserForm(props: UserFormProps) {
    const {
        title,
        isSubmitting,
        isSubmitted,
        isLoading = false,
        loadError,
        isEdit,
        onSubmit,
        onClose,
    } = props;
    const {
        roles,
        formValues,
        form,
        foundStaff,
        isShowingFoundUsers,
        isMaxUsersReached,
        loading,
        actions,
        errors,
    } = useUserForm();

    const btnText = isEdit ? "Edit User" : "Create";
    const userSearchContainerRef = useRef<HTMLDivElement>(null);

    useClickAway(
        userSearchContainerRef,
        () => isShowingFoundUsers && actions.toggleShowFoundUsers(false)
    );

    useEffect(() => {
        if (isSubmitted) onClose();
    }, [isSubmitted, onClose]);

    const onSearchBarFocus = () => !isShowingFoundUsers && actions.toggleShowFoundUsers(true);
    const usersErrorMsg = errors.searchUsersError?.message ?? errors.usersValidation?.message;

    return (
        <Modal title={title} topSpace={40} isOpen={true} onClose={onClose} autoclose={true}>
            <WithLoader isLoading={isLoading} error={loadError}>
                <Box>
                    <Form form={form}>
                        <div style={{ position: "relative" }} ref={userSearchContainerRef}>
                            <SearchBar
                                value={""}
                                placeholder="Users Search..."
                                onFocus={onSearchBarFocus}
                                onChange={actions.onSearch}
                                isSearching={loading.isSearchingUsers}
                                isDisabled={isMaxUsersReached}
                            />
                            {usersErrorMsg && (
                                <Text size={theme.FontSizes.sm} color={theme.PrimaryRed}>
                                    <i>{usersErrorMsg}</i>
                                </Text>
                            )}

                            {hasItems(foundStaff) && (
                                <Box
                                    display="flex"
                                    style={{
                                        position: "absolute",
                                        top: "110%",
                                        zIndex: theme.Layer.third,
                                        background: "white",
                                        maxHeight: 250,
                                        overflowY: "scroll",
                                        width: "100%",
                                        flexDirection: "column",
                                        boxShadow: theme.Elevate.mid,
                                    }}
                                >
                                    {isShowingFoundUsers &&
                                        foundStaff?.map((eachStaff, index) => (
                                            <Box
                                                onClick={() => actions.addUser(eachStaff)}
                                                key={eachStaff.staffId}
                                                style={{
                                                    cursor: "pointer",
                                                    border: cardBorder,
                                                    borderTop: index === 0 ? cardBorder : "none",
                                                }}
                                            >
                                                <UserCard
                                                    id={eachStaff.staffId}
                                                    name={eachStaff.fullName}
                                                    avatarSrc={eachStaff.pictureUrl}
                                                    subtext={eachStaff.email}
                                                />
                                            </Box>
                                        ))}
                                </Box>
                            )}
                        </div>

                        <Spacer space={8} />
                        {isMaxUsersReached && (
                            <Text color={theme.PrimaryOrange} size=".8em">
                                {MAX_USER_SIZE_MSG}
                            </Text>
                        )}
                        <Spacer space={8} />
                        {formValues.users && (
                            <Box
                                style={{
                                    border: cardBorder,
                                }}
                            >
                                {formValues.users.map((user) => (
                                    <UserCard
                                        id={user.staffId}
                                        key={user.staffId}
                                        avatarSrc={user.pictureUrl}
                                        name={user.fullName}
                                        subtext={user.email}
                                        onDelete={actions.removeUser}
                                    />
                                ))}
                            </Box>
                        )}

                        <Spacer space={24} />
                        <Form.Select name="unit" label="Unit" options={unitsOptions} spaceBottom />
                        <Spacer space={24} />

                        <Select
                            label="Add Roles/Privileges"
                            options={roles}
                            getOptionLabel={({ name }) => snakeToTitleCase(name)}
                            getValueLabel={({ id }) => id}
                            valueAndLabel
                            onChange={actions.addRole}
                            value={{ label: "", value: "" }}
                            isLoading={loading.isLoadingRoles}
                        />
                        {errors.roleRequestValidation && (
                            <Text color={theme.PrimaryRed} size={theme.FontSizes.sm}>
                                <i>{errors.roleRequestValidation.message}</i>
                            </Text>
                        )}
                        {formValues.userRoleRequest?.map((role) => (
                            <Box key={role.id} style={{ border: cardBorder, paddingLeft: "8px" }}>
                                <SelectedRole role={role} onDelete={actions.removeRole} />
                            </Box>
                        ))}

                        <Spacer space={32} />
                        <Grid default="1fr">
                            <Button progress={isSubmitting} onClick={form.handleSubmit(onSubmit)}>
                                {isSubmitting ? "Submitting..." : btnText}{" "}
                            </Button>
                            <Button pale onClick={onClose}>
                                Cancel
                            </Button>
                        </Grid>
                    </Form>
                </Box>
            </WithLoader>
        </Modal>
    );
}
