import { Notify } from "@flexisaf/flexibull2";

import { baseApi } from "@/store/base-api";
import { Methods, Unit } from "@/utils/enums";
import { ERROR_ALERT } from "@/utils/constants";
import { QueryFulfilledError } from "@/types/common";
import { UserRole } from "@/features/authorization/";
import { Department, Faculty } from "@/features/users/";

export const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (data) => ({
                url: "/auth",
                method: Methods.Post,
                data,
            }),
            transformResponse: transformLoginResponse,
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: "/auth",
                method: Methods.Put,
            }),
        }),
        accountVerification: builder.query<void, string>({
            query: (token) => ({
                url: "/account-verification",
                data: { token },
            }),
        }),
        forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordParams>({
            query: (data) => ({
                url: "/auth/password-reset-request",
                method: Methods.Post,
                data,
            }),
        }),
        refreshToken: builder.mutation<RefreshTokenResponse, string>({
            query: (token) => ({
                url: `/aut/refresh-token/${token}`,
            }),
        }),
        passwordReset: builder.mutation<ResetPasswordResponse, PasswordResetArgs>({
            query: (data) => ({
                url: "/auth/me/password-change",
                method: Methods.Post,
                data,
            }),
        }),
        verifyEmail: builder.mutation<any, string>({
            query: (token) => ({
                url: "students/email-verification-update",
                params: { token },
                method: Methods.Put,
            }),
        }),
        sendVerificationLink: builder.mutation<any, string>({
            query: (email) => ({
                url: "/students/email-verification",
                params: { "email-address": email },
            }),
            async onQueryStarted(_args, { queryFulfilled: qf }) {
                qf.then((res) => Notify(res.data.details)).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: ERROR_ALERT })
                );
            },
        }),
    }),
});

function transformLoginResponse(resp) {
    const { user: rawUser, ...restResp } = resp;
    const user = { ...rawUser, currentRole: rawUser.roles[0] };

    return {
        ...restResp,
        user,
    };
}

export interface LoginResponse {
    access_token: string;
    expires_in: number;
    secret_change_onLogin: boolean;
    token_type: string;
    user: AuthUser;
}

export interface AuthUser {
    contacts: Contacts[];
    id: string;
    roles: UserRole[];
    currentRole: UserRole;
    firstName: string;
    lastName: string;
    username: string;
    organisation: string;
    verified: boolean;
    unit: Unit;
    faculty?: Faculty;
    department?: Department;
}

export interface Contacts {
    contact: string;
    contactType: string;
    verified: boolean;
}

export interface LoginRequest {
    username: string;
    password: string;
}

export interface ForgotPasswordParams {
    "student-id": string;
}

export interface ForgotPasswordResponse {
    message: string;
}

export interface PasswordResetArgs {
    token: string;
    user_id: string;
    password: string;
}

export interface ResetPasswordResponse {
    message: string;
}

export interface RefreshTokenResponse {
    token: string;
}
export const {
    useVerifyEmailMutation,
    useAccountVerificationQuery,
    useLoginMutation,
    useLogoutMutation,
    useForgotPasswordMutation,
    usePasswordResetMutation,
    useSendVerificationLinkMutation,
} = authApi;
