import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useUpdateUserMutation, useGetUserQuery } from "../users-api";
import { ApiError } from "@/types/common";
import { Role, useGetRolesQuery } from "@/features/authorization";

type RoleIdAcc = Record<string, boolean>;

export function useUserProfile() {
    const { id } = useParams();

    const { data: user, ...userMeta } = useGetUserQuery(<string>id);
    const { data: rolesData, ...getRolesMeta } = useGetRolesQuery();
    const [updateUserReq, updateeUserMeta] = useUpdateUserMutation();

    const form = useForm();
    const formValues = form.watch();
    const { userRoleRequest, unit } = formValues;

    useEffect(() => {
        if (user) {
            const roles = user.roles.map(({ role }) => role);
            form.setValue("userRoleRequest", roles);
            form.setValue("id", user.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const updateUser = (body) => updateUserReq(transformUpdateUserRequest(body));

    const roles = useMemo(() => {
        const userRoleMapById = userRoleRequest
            ? userRoleRequest.reduce((acc: RoleIdAcc, role: Role) => {
                  acc[role.id] = true;
                  return acc;
              }, {})
            : {};

        const roleFilter = (role: Role) => {
            if (userRoleMapById[role.id]) return false;

            if (unit) {
                return role.description === unit;
            }
            return role;
        };

        return rolesData?.entities.filter(roleFilter);
    }, [rolesData, unit, userRoleRequest]);

    const addRole = (role: Role) => {
        const newRoles = userRoleRequest ? [...userRoleRequest, role] : [role];
        form.setValue("userRoleRequest", newRoles);
    };

    const removeRole = (roleId: string) => {
        if (userRoleRequest) {
            form.setValue(
                "userRoleRequest",
                userRoleRequest.filter(({ id }) => id !== roleId)
            );
        }
    };

    const loading = {
        isLoading: userMeta.isLoading,
        isLoadingRoles: getRolesMeta.isLoading,
        isUpdatingUser: updateeUserMeta.isLoading,
    };
    const errors = {
        userError: <ApiError>userMeta.error,
        updateUserError: <ApiError>updateeUserMeta.error,
    };

    const actions = { updateUser, addRole, removeRole };

    return {
        roles,
        form,
        user,
        formValues,
        loading,
        errors,
        actions,
    };
}

const transformUpdateUserRequest = (updateUserBody) => {
    const { userRoleRequest, id, unit: staffType } = updateUserBody;
    const roles = userRoleRequest.map(({ id }) => ({ id }));
    return { id, roles, staffType };
};
