import { INSTITUTION_KEY } from "@/utils/constants";

const index = `/:${INSTITUTION_KEY}`;
const withIndex = (path: string) => `${index}${path}`;

function getPreviousMeetingPath(id: string = ":id"): string {
    return withIndex(`/meetings/previous/${id}`);
}

function getPrayerByIdPath(id: string = ":id"): string {
    return withIndex(`/prayers/${id}`);
}

function editPrayerPath(id: string = ":id"): string {
    return withIndex(`/prayers/edit/${id}`);
}

function getPrayerCommentsPath(prayerId: string = ":prayerId"): string {
    return withIndex(`/comments/${prayerId}`);
}

function getOngoingAttendance(id: string = ":id"): string {
    return withIndex(`/meetings/ongoing/${id}/attendance`);
}

export const appPaths = {
    // PUBLIC
    login: "/login",
    accountVerification: "/account-verification",
    passwordReset: "/reset-request",
    forgotPassword: "/forgot-password",
    dashboard: withIndex("/dashboard"),

    // MEETING
    meetings: withIndex("/meetings"),
    newMeetings: withIndex("/meeting/new"),
    prayers: withIndex("/prayers"),
    getMeeting: (id = ":id") => withIndex(`/meetings/${id}`),
    getOngoingMeeting: (id = ":id") => withIndex(`/meetings/ongoing/${id}`),
    previousMeeting: withIndex("/meetings/previous"),
    getPastMeeting: getPreviousMeetingPath,
    getOngoingAttendance,

    // PRAYER ITEMS
    createPrayer: withIndex("/prayers/create"),
    getPrayerById: getPrayerByIdPath,
    editPrayer: editPrayerPath,
    getPrayerComments: getPrayerCommentsPath,

    // AGENDA
    agendas: withIndex("/agendas"),
    getAgenda: (id = ":id") => withIndex(`/agendas/${id}`),
    createAgenda: withIndex("/agendas/create"),
    getEditAgenda: (id = ":id") => withIndex(`/agendas/edit/${id}`),

    // USERS
    users: withIndex("/users"),
    createUser: withIndex("/users/create"),
    getUser: (id = ":id") => withIndex(`/users/${id}`),
    getMyProfile: (id = ":id") => withIndex(`/me/${id}`),
    getEditUser: (id = ":id") => withIndex(`/users/edit/${id}`),

    // NOTIFICATION
    notification: withIndex("/notification"),
};
